import { defineComponent } from "vue";
export default defineComponent({
  name: "box-little-component",
  props: {
    src: {
      type: String,
      default: "svg/icons/image-file-thummbnail.svg"
    },
    alt: {
      type: String,
      default: "image-icon"
    },
    title: {
      type: String,
      required: false
    },
    description: {
      type: String,
      required: false
    },
    background: {
      type: String,
      default: "#fff4dc"
    },
    uploadImage: {
      type: Function
    }
  },
  components: {},
  setup: function setup() {
    return {};
  }
});