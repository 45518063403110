import { defineComponent } from "vue";
export default defineComponent({
  name: "menu-left",
  props: {
    isCompare: {
      type: Object,
      required: true
    },
    handleCompare: {
      type: Function,
      required: true
    },
    openModalFloor: {
      type: Function,
      required: true
    },
    openModalWall: {
      type: Function,
      required: true
    },
    openModalEspace: {
      type: Function,
      required: true
    }
  }
});