import { buildTreatedImageUrl } from "@/core/helpers/config";
import store from "@/store";
import { computed, defineComponent } from "vue";
import ShareModal from "./modals/modal-component/ShareModal.vue";
export default defineComponent({
  name: "menu-bottom",
  props: {
    handleShare: {
      type: Function
    },
    printImage: {
      type: Function
    },
    handleDownload: {
      type: Function
    },
    handleZoom: {
      type: Function
    }
  },
  components: {
    ShareModal: ShareModal
  },
  setup: function setup() {
    var colorValue = computed(function () {
      return store.getters.getwallColor;
    });
    var floorPattern = computed(function () {
      return store.getters.getFloorPattern;
    });
    var imgName = computed(function () {
      return store.getters.getImgName;
    });
    var sharingURL = computed(function () {
      var u = buildTreatedImageUrl(imgName.value, {
        color: colorValue.value,
        floor: floorPattern.value
      });
      return u;
    });
    return {
      sharingURL: sharingURL
    };
  }
});