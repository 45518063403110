import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-8cdc6a22"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = ["src", "alt"];
var _hoisted_2 = {
  class: "upload-button-pargraph"
};
var _hoisted_3 = {
  class: "upload-button-description"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("label", {
    for: "file",
    class: "upload-button-container",
    style: _normalizeStyle("background: ".concat(_ctx.background))
  }, [_createElementVNode("img", {
    class: "upload-button-img",
    src: _ctx.src,
    alt: _ctx.alt
  }, null, 8, _hoisted_1), _createElementVNode("p", _hoisted_2, [_createTextVNode(_toDisplayString(_ctx.title), 1), _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.description), 1)]), _createElementVNode("input", {
    onChange: _cache[0] || (_cache[0] = //@ts-ignore
    function () {
      return _ctx.uploadImage && _ctx.uploadImage.apply(_ctx, arguments);
    }),
    style: {
      "display": "none"
    },
    type: "file",
    name: "file",
    id: "file",
    class: "inputfile",
    accept: "image/png, image/jpg, image/jpeg"
  }, null, 32)], 4);
}