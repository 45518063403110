import { buildTreatedImageUrl } from "@/core/helpers/config";
import store from "@/store";
import { computed, defineComponent, ref } from "vue";
import { ElNotification } from "element-plus";
export default defineComponent({
  name: "zoom-modal",
  components: {},
  setup: function setup() {
    var networks = ref([{
      network: "email",
      name: "Email",
      icon: "svg/icons/btn_email.svg",
      style: "color: #ffa621; border: 1px solid #ffa621"
    }, {
      network: "facebook",
      name: "Facebook",
      icon: "svg/icons/btn_facebook.svg",
      style: "color: #ffa621; border: 1px solid #ffa621"
    }]);
    var colorValue = computed(function () {
      return store.getters.getwallColor;
    });
    var floorPattern = computed(function () {
      return store.getters.getFloorPattern;
    });
    var imgName = computed(function () {
      return store.getters.getImgName;
    });
    var sharingURL = computed(function () {
      var u = buildTreatedImageUrl(imgName.value, {
        color: colorValue.value,
        floor: floorPattern.value
      });
      return u;
    });
    return {
      networks: networks,
      sharingURL: sharingURL,
      ElNotification: ElNotification
    };
  }
});